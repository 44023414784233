import React, { useState } from 'react'
import Demo1 from '../../components/Demo1'
import Title from '../../img/title.png'
import SteamLogo from '../../img/steam.png'
import TwitterLogo from '../../img/xlogo.png'

const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: 'linear-gradient(0deg, rgba(24,14,29,1) 0%, rgba(59,34,71,1) 100%)',
  backgroundRepeat: 'noRepeat',
  backgroundAttachment: 'fixed',
  height: '100%'
}

const bannerStyle = {
  width: '60vw',
  margin: '20px'
}

const title = {
  fontSize: '16px',
  fontWeight: 'bold',
  marginBottom: '10px',
  marginLeft: '15px',
  alignSelf: 'center',
  color: '#FEFEFE',
}

const title2 = {
  fontSize: '34px',
  fontWeight: 'bold',
  marginTop: '20px',
  marginLeft: '50px',
  alignSelf: 'center',
  color: '#FEFEFE',
  marginTop: '40px'
}

const paragraph = {
  fontSize: '24px',
  alignSelf: 'center',
  color: '#FEFEFE',
  margin: '40px',
  fontWeight: 'bold'
}

const demoStyle = {
  display: 'flex',
  width: '60vw',
  marginBottom: '20px',
  marginLeft: 'auto',
  marginRight: 'auto',
  flexShrink: '0',
  margin: '20px',
  height: 'auto',
  width: '55em',
  border: '8px solid #afb9c966',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  backgroundSize: 'cover',
  boxSizing: 'borderBox',
  borderRadius: '0%',
  backgroundImage: 'linearGradient(#f9f047, #0fd850)',
  boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
  alignItems: 'center',
  justifyContent: 'center'
}

const paragraph_small = {
  fontSize: '20px',
  alignSelf: 'flex-start',
  marginTop: '50px',
  marginLeft: '40px',
  color: '#FEFEFE'
}

const SteamStyle = {
  display: 'flex',
  flex: '1,1,auto',
  width: '40px',
  height: '40px',
  alignItems: 'center',
}

const ImageStyle = {
  display: 'flex',
  flex: '1,1,auto',
  width: '60vh',
  height: '60vh',
  marginTop: '2em'
}

const description = {
  fontSize: '20px',
  lineHeight: '1.5em',
  fontWeight: '600',
  fontVariant: 'small-caps',
  textShadow:'1px 1px 10px #fff, 1px 1px 10px #ccc'
}

const linkContainers = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}

const soloContainers = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const quickLinks = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  border: '4px solid #afb9c966',
  borderRadius: '15px',
  width: '20em',
  height: '8em',
  paddingBottom: '10px',
  marginBottom: '20px',
}

const bottomTitles = {
  display: 'flex',
  fontSize: '16px',
  fontWeight: 'bold',
  alignSelf: 'center',
  color: '#FEFEFE',
  marginTop: '15px'
}

const bottomContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '5em'
}

const filler = {
  height: '5em'
}

const leftGif = {
  position: 'fixed',
  left: '0px',
  top: '25%',
  height: '18em',
  width: 'auto'
}

const rightGif = {
  position: 'fixed',
  right: '0px',
  top: '25%',
  height: '18em',
  width: 'auto'
}

const topContainer = {
  display: 'flex',
  flexDirection: 'row',
}

export default function HomePage () {
  // const [play, setPlay] = useState(false)

  return (
    <div style={container}>
      {/* {!play && ( */}
        <div style={{ height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '40px'}}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div style={topContainer}>
              {/* <img src={ZackGif} style={leftGif} /> */}
              <img src={Title} style={ImageStyle} />
              {/* <img src={ZackGif} style={rightGif} /> */}
            </div>
              <div style={{ display: 'flex', flexDirection: 'column', color: 'white', alignItems: 'center', justifyContent: 'center', margin: '20px' }}>
                
                <div style={description}>
                  A story driven 2D platformer
                </div>
                <div style={description}>
                  Inspired by Inti-creates games such as Megaman Zero and Azure Striker Gunvolt
                </div>

                <div style={filler}></div>

                <div style={demoStyle}>
                  <Demo1 /> 
                </div>
              </div>
            </div>
          </div>

      <div style={bottomContainer}>
        <div style={quickLinks}>
        <h1 style={bottomTitles}>Quick Links</h1>

          <div style={linkContainers}>
            <div style={soloContainers}>
              <div style={title}>
                Steam
              </div>
              <div onClick={() => window.open('https://store.steampowered.com/app/2544220/Chrono_Mirror/')} style={{ marginLeft: '20px', cursor: 'pointer' }}>
                <img src={SteamLogo} style={SteamStyle} />
              </div>
            </div>

            <div style={soloContainers}>
              <div style={title}>
                Twitter/X
              </div>
              <div onClick={() => window.open('https://twitter.com/FIndiedev')} style={{ marginLeft: '20px', cursor: 'pointer' }}>
                <img src={TwitterLogo} style={SteamStyle} />
              </div>
            </div>
          </div>
        </div>

          <div style={quickLinks}>
          <h1 style={bottomTitles}>Latest News from Dev</h1>
            <div style={linkContainers}>
              <em style={title}>Coming Soon</em>
            </div>
          </div>

          <div style={quickLinks}>
          <h1 style={bottomTitles}></h1>
            <div style={linkContainers}>
              
            </div>
          </div>
        </div>

          {/* Old april fools stuff below */}
          {/* <div style={paragraph}>
            We have changed our monetization model from premium to free to play! Everyone can now enjoy Chrono Mirror for free!
          </div>
          <div style={{ display: 'flex', width: '90vw', justifyContent: 'space-between' }}>
            <div>
              <img src={Banner} alt='banner.png' style={bannerStyle} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={title2}>
                Receive 300 Free Summons during the month of April!
              </div>
              <div style={paragraph_small}>
                Chrono Mirror is now free to play! Enhance each playthrough experience and mission by using different team compositions and strategies! Obtain new characters by
                using Shards of Fate to summon them to your timeline!
              </div>
              <div style={paragraph_small}>
                Receive 10 Shards of Fate (10 summons) each day for the whole month of April by logging in and registering today!
              </div>
              <div style={paragraph_small}>
                Download the game to register and start your journey!
              </div>
              <div onClick={() => setPlay(true)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#2ec2f0', color: '#000000', width: '250px', padding: '20px', borderRadius: '5px', cursor: 'pointer', alignSelf: 'center', justifySelf: 'center', marginTop: '30px' }}>
                Download Chrono Mirror v0.29
              </div>
            </div>
          </div>
        </div>
      )}
      {play && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <div style={title}>
            April Fools!
          </div>
          <div style={{ width: '100%' }}>
            <Video />
          </div>
          <div style={{ margin: '20px' }}>
            Please forgive us :)
          </div>
        </div>
      )} */}
      </div>
    </div>
  )
}