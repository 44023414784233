import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import reportWebVitals from './components/reportWebVitals';
import Root from './routes/root.jsx'
import ErrorPage from './error-page.jsx';
import HomePage from './components/PageComponents/HomePage.jsx'
import TeamPage from './components/PageComponents/TeamPage.jsx'
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

const router = createHashRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to='/home' replace /> },
      {
        path: '/home',
        element: <HomePage />
      },
      {
        path: '/team',
        element: <TeamPage />
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
