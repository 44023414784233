import '../css/Button.css';

export default function Button (props) {
  const text = props.text
  const handleClick = props.handleClick
  const curPage = props.curPage

  return (
    <div onClick={() => handleClick()} className="button" style={{ backgroundColor: curPage ?  '#153875' : undefined, textDecoration: 'none' }}>
      {text}
    </div>
  )
}