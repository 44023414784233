import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import Rick from '../videos/compiled_video.mp4'

class Demo1 extends Component {
    render () {
        return (
        <div className='player-wrapper'>
          <ReactPlayer
            className='react-player fixed-bottom'
            url={Rick}
            width='100%'
            height='100%'
            controls={false}
            playing={true}
            loop={true}
            volume={0}
          />
        </div>
        )
    }
}

export default Demo1;