import React from "react";
import Card from '../Card.jsx'
import BuddyImg from '../../img/team_images/rebecca_icon.png'
import VnittiImg from '../../img/team_images/vnitti.jpg'
import JapadaImg from '../../img/team_images/japada.jpg'
import GuzArtImg from '../../img/team_images/guzart.png'
import MichaelImg from '../../img/team_images/michaelgaddi.jpg'
import CamronImg from '../../img/team_images/camron.jpg'
import FalonImg from '../../img/team_images/falon.jpg'
import DelaImg from '../../img/team_images/dela.jpg'
import SarahImg from '../../img/team_images/sarah.jpg'
import MarybelleImg from '../../img/team_images/marybelle.jpg'
import JohnImg from '../../img/team_images/john.jpg'
import VeraImg from '../../img/team_images/vera.jpg'
import MarisaImg from '../../img/team_images/marisa.jpg'
import OliverImg from '../../img/team_images/oliver.jpg'
import CarolineImg from '../../img/team_images/caroline2.jpg'
import LeeImg from '../../img/team_images/lee.jpg'
import MelissaImg from '../../img/team_images/melissa.jpg'
import SkyImg from '../../img/team_images/sky.jpg'
import VivianImg from '../../img/team_images/vivian.jpg'
import RooImg from '../../img/team_images/roo.jpg'
import AndreaImg from '../../img/team_images/andrea.jpg'
import AllenImg from '../../img/team_images/allen.jpg'
import SoundwerxImg from '../../img/team_images/soundwerx.jpeg'
import BassImg from '../../img/team_images/bass.jpg'

const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(0deg, rgba(24,14,29,1) 0%, rgba(59,34,71,1) 100%)',
  backgroundRepeat: 'noRepeat',
  backgroundAttachment: 'fixed',
  height: '100%',
  color: '#FEFEFE',
  fontFamily: 'Tahoma'
}

const mainTitle = {
  fontSize: '42px',
  fontWeight: 'bold',
}

const title = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginTop: '20px',
  alignSelf: 'center'
}

const cardContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignSelf: 'center',
  margin: '20px 80px 80px 80px'
}

const titleContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: '#afb9c966',
  padding: '20px',
  marginTop: '15px',
  width: '30%',
  borderRadius: '10px',
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
}

export default function TeamPage () {
  return (
    <div style={container}>
      <div style={titleContainer}>
        <div style={mainTitle}>
          Our Team
        </div>
        <div style={title}>
          Visual Arts and Animators
        </div>
      </div>
      <div style={cardContainer}>
        <Card
          name='Buddy Manarte'
          title='Illustrator'
          imgRef={BuddyImg}
          link='https://buddymanarte.carrd.co'
        />
        <Card
          name='Vicente Nitti'
          title='Pixel Animator'
          imgRef={VnittiImg}
          link='https://vnitti.itch.io'
        />
        <Card
          name='Michael Gaddi'
          title='Graphical Desgin/Logo Design'
          imgRef={MichaelImg}
          link='https://www.artstation.com/michael_gaddi'
        />
      </div>
      <div style={title}>
        Voice Actors
      </div>
      <div style={cardContainer}>
        {/* <Card
          name='Camron Mahler'
          title='Voice Actor - Roles: Zack, Leinholte Soldiers'
          imgRef={CamronImg}
          link='http://camronmahler.com'
        /> */}
        <Card
          name='Falon Echo'
          title='Voice Actor - Roles: Kira'
          imgRef={FalonImg}
          link='https://twitter.com/FalonEcho'
        />
        <Card
          name='Vera Tan'
          title='Voice Actor - Roles: Destiny'
          imgRef={VeraImg}
          link='https://veratan.fun/'
        />
        <Card
          name='Sarah Ruth Thomas'
          title='Voice Actor - Roles: Ariza'
          imgRef={SarahImg}
          link='https://www.sarahruthvoice.com'
        />
        <Card
          name='Marybelle Sagard'
          title='Voice Actor - Roles: Kowa'
          imgRef={MarybelleImg}
          link='https://www.marybellesagard.com'
        />
        <Card
          name='John Patneaude'
          title='Voice Actor - Roles: Jack'
          imgRef={JohnImg}
          link='https://twitter.com/JohnPatneaude'
        />
        <Card
          name='Andrea Avery'
          title='Voice Actor - Roles: Tharia'
          imgRef={AndreaImg}
          link='https://www.andreaaveryvo.com'
        />
        <Card
          name='Marisa Duran'
          title='Voice Actor - Roles: Nagisa'
          imgRef={MarisaImg}
          link='https://www.marisa-duran.com'
        />
        <Card
          name='Oliver Smith'
          title='Voice Actor - Roles: Robert'
          imgRef={OliverImg}
          link='https://oliversmithvoiceactor.co.uk'
        />
        <Card
          name='Caroline Orejuela'
          title='Voice Actor - Roles: Nuage'
          imgRef={CarolineImg}
          link='https://www.carolineorejuela.com'
        />
        <Card
          name='Lee Bergantino'
          title='Voice Actor - Roles: Abigail'
          imgRef={LeeImg}
          link='https://www.leebergantino.com'
        />
        <Card
          name='Vivian Reed'
          title='Voice Actor - Roles: Mori'
          imgRef={VivianImg}
          link='https://vivianreedva.com'
        />
        <Card
          name='Melissa White'
          title='Voice Actor - Roles: Lavender'
          imgRef={MelissaImg}
          link='https://www.melissa-white.com'
        />
        <Card
          name='Sky Rae'
          title='Voice Actor - Roles: Anna'
          imgRef={SkyImg}
          link='https://www.skyraevoicing.com'
        />
        <Card
          name='Roo Ryder'
          title='Voice Actor - Roles: Adeline'
          imgRef={RooImg}
          link='https://www.rooryder.com'
        />
      </div>
      <div style={title}>
        Music/SFX
      </div>
      <div style={cardContainer}>
        <Card
          name='Allen J Mills'
          title='Composer'
          imgRef={AllenImg}
          link='https://twitter.com/AJMillsMusic'
        />
        <Card
          name='Orb Soundwerx'
          title='Sound Effects'
          imgRef={SoundwerxImg}
          link='https://www.orbsoundwerx.com'
        />
      </div>
      <div style={title}>
        Other
      </div>
      <div style={cardContainer}>
        <Card
          name='Enoc Mendoza'
          title='Founder and Lead Programmer'
          imgRef={BassImg}
          link='forteindiedev.com'
        />
      </div>
    </div>
  )
}